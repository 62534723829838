<template>
    <div id="container">
        <div id="loginContainer">
        <div class="pictureContainer">
            <img class="logo" src="https://cdn.protonrp.com/img/logo/prp_white_trans.png">
        </div>
        <div class="discordContainer">
            <form>
                <h1>THANK YOU! &#128075; </h1>
            <div class="formContainer">
                <div class="formDiv">
                    <p class="titleText">LITHIUM DASHBOARD AUTHORIZED</p>
                    <br>
                    <p class="subText">Thank you for signing in, you will be redirected to the dashboard shortly! If you do not get redirected automatically, click <router-link to="dashboard">here</router-link></p>
                    <br>
                    <p class="subText">Current Version: {{ details }}</p>
                </div>
                </div>
            </form>
            <p class="psecond text-muted">© 2021 Nullified. All rights reserved.</p>
        </div>
        
        </div>
    </div>
</template>

<script>
const axios = require('axios');

export default {
    beforeCreate() {
        const code = this.$route.query.code;
        const state = Buffer.from(decodeURIComponent(this.$route.query.state), 'base64').toString('ascii');

        if (localStorage.getItem('oauth-state') != state) {
            this.$router.push('/');
            return {
                error: 'Invalid state token received'
            }
        }

        axios({
            method: 'post',
            url: `${process.env.VUE_APP_APIURL}/token`,
            data: {
                code: code,
            },
            withCredentials: true
        }).then(res => {
            setTimeout(() => {
                this.$router.push('/dashboard');
            }, 5000);
        }).catch(err => {
            console.error(err);
        })
    },
    data() {
        return {
            details: `${process.env.VUE_APP_NAME} v${process.env.VUE_APP_VERSION}`,
        };
    },
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Work+Sans:300,400,700,900');

* {
    outline-width: 0;
	font-family: 'Montserrat' !important;
}

body {
    background: #23272A;
}

form {
    position: relative;
    align-items: center;
    height: 100%;
}

img {
    width: 150px;
    margin-bottom: -5px;
    display: block;
    position: relative;
}

img:first-child {
    width: 150px;
}

.text {
    padding: 25px 0 10px 0;
    margin-top: 0;
    opacity: 1;
    transition: 0.8s ease;
}

.logo {
    position: relative;
    top: 0;
    opacity: 1;
    transition: 0.8s ease;
}

h1 {
    margin: 0 0 15px 0;
    font-family: 'Work Sans' !important;
    font-weight: 700;
    font-size: 20px;
    color: #fff;
    user-select: none;
    opacity: 1;
    left: 0;
    position: relative;
    transition: 0.5s ease;
    text-align: center;
}

.formContainer {
    text-align: left;
}

.formDiv {
    margin-bottom: 30px;
    left: 0;
    opacity: 1;
    transition: 0.5s ease;
    position: relative;
}

.formDiv:last-child {
    padding-top: 10px;
    margin-bottom: 0;
}

.titleText {
    margin: 0;
    font-weight: 700;
    color: #aaa;
    font-size: 15px;
    user-select: none;
    text-align: center;
}

.subText {
    margin: 0;
    font-weight: 500;
    color: #ddd;
    font-size: 12px;
    user-select: none;
    text-align: center;
}

#container {
    height: 100vh;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('https://cdn.protonrp.com/img/backgrounds/fivem_vehicle_wp.jpg');
}

#loginContainer {
    display: flex;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}

.discordContainer {
    padding: 45px 30px;
    box-sizing: border-box;
    width: 400px;
    margin-left: 0;
    overflow: hidden;
    opacity: 1;
    height: 431.5px;
    box-shadow: inset 0 0 0 3000px rgba(54, 57, 63, .75);
    transition: all 0.5s ease;
    backdrop-filter: blur(10px);
    background-size: cover !important;
}

.pictureContainer {
    padding: 45px 35px;
    box-sizing: border-box;
    scale: 1;
    position: relative;
    z-index: 2;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: 700 ease;
    backdrop-filter: blur(10px);
	box-shadow: inset 0 0 0 3000px rgba(40,43,48,.9);
}

.login-form {
    width: 65%;
    right: 0px;
    position: absolute;
    background-color: #9999;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formButton {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    bottom: 0;
    display: inline-block;
}

.psecond {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 5px;
    bottom: 5px;
    margin-bottom: 1px;
}

.button { 
    background-color: #5865f2;
    border: none;
    color: #fff;
    justify-content: center;
    width: 100%;
    border-radius: 28px;
    font-size: 20px;
    padding: 16px 32px;
    line-height: 24px;
    cursor: pointer;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    transition-property: background-color,color,color,box-shadow,-webkit-box-shadow;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
    font-family: "Whitney","Helvetica Neue","Helvetica","Arial",sans-serif;
}

.button:hover {
    background-color: hsl(235,86.1%,71.8%);
    color: #fff;
    box-shadow: 0 8px 15px rgb(255 255 255 / 20%);
}

.discord_icon {
    margin-right: 8px;
}

svg {
    width: 24;
    height: 24;
}
</style>